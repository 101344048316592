:root {
  --primary-color: #0000FF;
  --secondary-color: #FA4343;
  --white: #FFFFFF;
  --line-color: #E6E6FF;
  --text-color: #080708;
  --secondary-text: #333333;
  --lighter-purple: #C7164B;
  --background-color: #FAFBFF;
  --overlay-color: rgba(8, 7, 8, 0.9);
  --yellow-hover-color: #FEE090;
  --disabled-button-color: #FFF9E9;
  --blue-hover-color: #6795FF;
  --blue-disabled-color: #BED1FF;
  --text-light-color: #828282;
  --text-bold-color: #080708;
  --gray: #BDBDBD;
  --red: #EB5757;
  --gray-2: #4F4F4F;
  --red-hover: #FCA1A1;
}

body {
  margin: 0;
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  cursor: pointer;
}

button:active, button:focus {
  outline: none;
}

.hidden {
  display: none!important;
}

.main__container {
  width: 63%;
  margin: auto;
  margin-top: 40px;
}

.container__header {
  width: 100%;
}

.container__body {
  width: 100%;
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pull__right {
  float: right;
}

.regular__text__bold {
  font-size: 16px;
  line-height: 20px;
  color: var(--text-bold-color);
  font-weight: bold;
}

.regular__text {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-light-color);
  overflow-wrap: break-word;
}

.container__header__options {
  width: 40px;
  height: 40px;
  background: var(--white);
  border-radius: 5px;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  line-height: 3;
}

.container__header__options:hover {
  background: var(--line-color);
}

.header__options__icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.new__video__button {
  width: 160px;
  height: 50px;
  background: var(--secondary-color);
  border-radius: 5px;
  border: none;
  color: var(--white);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  position: absolute;
  right: 18.6%;
  top: 106px;
  display: none!important;
}

.pointer__cursor {
  cursor: pointer;
}

.download {
  background: url(./images/download.svg);
}

.share {
  background: url(./images/share.svg);
}

.delete {
  background: url(./images/delete.svg);
}

.folder {
  background: url(./images/folder.svg);
}

.info {
  background: url(./images/info.svg);
  height: 22px!important;
  width: 4px!important;
}

.video {
  background: url(./images/video.svg);
  height: 15px!important;
  width: 22px!important;
}

.user {
  background: url(./images/user.svg);
}

.float__block__right {
  display: inline-block;
  float: right;
}

.no__border {
  border: none;
}

.no__background {
  background: none;
}

.boring__link {
  text-decoration: none;
}

.regular__text__bold.boring__link:hover {
  color: var(--primary-color);
}

.regular__text__bold.boring__link.active {
  pointer-events: none;
}

/** Begin tooltip */

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 76px;
  height: 40px;
  background-color: #333333;
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 0%;
  margin-left: -5px;
  line-height: 3!important;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 77%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (max-width: 600px) {
  .main__container {
    width: unset;
    margin: 20px 20px;
  }
  .new__video__button {
    display: none!important;
  }
  .hidden__mobile {
    display: none!important;
  }
}