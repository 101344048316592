.logo {
  width: 100%;
  text-align: center;
  height: 65px;
  box-sizing: border-box;
}

.signup__text {
  font-weight: bold;
  font-size: 41.89px;
  line-height: 26px;
  color: var(--text-bold-color);
  text-align: center;
  width: 100%;
  margin-top: 20px;
}

.signup__description {
  width: 75%;
  margin: auto;
  margin-top: 25px;
  color: var(--gray-2);
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.login__button {
  width: 325px;
  height: 50px;
  border-radius: 5px;
  color: white;
  border: 0px;
  line-height: 2;
  cursor: pointer;
}

.google__button {
  background: #DB4437;
}

.dropbox__button {
  background: #0060FF;
}

.center__text {
  text-align: center;
}

.login__divider {
  width: 0px;
  height: 30px;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  float: left;
  margin-left: 15px;
}

.icon {
  height: 24px;
  width: 24px;
  float: left;
  margin-top: 2px;
  margin-left: 12px;
}

.google {
  background: url(../images/google.svg);
}

.dropbox {
  background: url(../images/dropbox.svg);
}