.video__card {
  width: 30%;
  display: inline-block;
  margin-right: 5%;
}

.video__card:nth-child(3n) {
  margin-right: 0px;
}

.video__card:nth-child(n + 4) {
  margin-top: 60px;
}

.video__card__large {
  width: 60%;
}

.thumbnail {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0) 31.67%), url(../../images/thumbnail.svg), #C4C4C4;
  background-repeat: space;
  background-position: center;
  width: 100%;
  height: 180px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.thumbnail:hover {
  box-shadow: 0px 4px 30px rgba(218, 200, 233, 0.3);
}

.thumbnail__large {
  width: 100%;
  height: 423px;
  box-shadow: 0px 4px 30px rgba(218, 200, 233, 0.3);
  position: relative;
  cursor: pointer;
  border: none;
  border-radius: 8px;
}

.video__description {
  margin-top: 10px;
}

.video__link {
  padding: 0;
}

.video__description__large {
  margin-top: 20px;
}

.video__description__hr {
  border: 0.5px solid var(--line-color);
  opacity: 0.3;
}

.video__footer {
  display: inline-block;
  width: 100%;
}

.video__icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  background-blend-mode: normal;
  border-radius: 50%;
}

.video__icon__large {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-blend-mode: normal;
  border-radius: 50%;
}

.avatar {
  background: url(../../images/video_avatar.svg);
}

.user__info {
  margin-left: 15px;
  display: inline-block;
  vertical-align: super;
  text-align: left;
}

.user__info__large {
  margin-left: 15px;
  display: inline-block;
  vertical-align: super;
  text-align: left;
}

.link__copy {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 10px;
  bottom: 10px;
  background: var(--white);
  border-radius: 5px;
  line-height: 2.8;
  text-align: center;
  cursor: pointer;
}

.link__copy:hover, .link__copy:active, .link__copy:focus {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}

.link__copy__icon {
  display: inline-block;
  background: url(../../images/link.svg);
  width: 17px;
  height: 17px;
}

.options__icon__button {
  border: none;
  background: none;
}

.options__icon__button:focus {
  outline: none;
}

.options__icon {
  display: inline-block;
  mask: url(../../images/options.svg);
  width: 18px;
  height: 4px;
  background: var(--text-light-color);
}

.options__dropdown {
  width: max-content;
  height: min-content;
  background: var(--white);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  right: 3%;
  display: none;
  z-index: 9;
}

.display__block {
  display: block;
}

.options__dropdown>ul {
  list-style-type: none;
  padding: 0;
  margin: 10px;
}

/* .options__dropdown>ul>li {
  margin-left: 5px;
} */

.options__dropdown>ul>li:first-child {
  line-height: 2;
}

.options__dropdown>ul a, .options__dropdown>ul button {
  font-weight: bold;
  font-size: 12px;
  align-items: center;
  color: var(--text-light-color);
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .video__card {
    width: 100%;
    margin-right: unset;
    margin-bottom: 30px;
  }
  .video__card:nth-child(n + 4) {
    margin-top: unset;
  }
  .thumbnail {
    box-shadow: 0px 4px 30px rgba(218, 200, 233, 0.3);
  }
  .video__card__large {
    width: 100%;
  }
  .thumbnail__large {
    height: 291px;
    box-shadow: none;
    position: absolute;
    top: 30vh;
    left: 0;
  }
  .video__description__large {
    display: none;
  }
  .video__main__container .video__footer {
    display: none;
  }
}