:root {
  --spacing: 20px;
  --secondary-text: #333333;
  --accent-color: #9BE858;
  --preview-background: #F2F2F2;
  --gray-3: #E0E0E0;
  --alt-overlay-color: rgba(8, 7, 8, 0.8);
  --black: #000000;
  --neutral-black: #151522;
  --neutral-gray: #E4E4E4;
}

/* html {
  border: 0.3px solid var(--secondary-color);
  box-shadow: 0px 4px 10px rgba(220, 214, 214, 0.25);
} */

#horus-timer {
  display: none;
}

hr {
  /* width: 300px; */
  height: 0px;
  border: 0.5px solid var(--secondary-color);
  opacity: 0.3;
}

.main__container {
  position: fixed;
  top: 20px;
  right: 40px;
  width: 300px;
  background: var(--white);
  border: 0.3px solid var(--line-color);
  box-shadow: 0px 4px 10px rgba(220, 214, 214, 0.25);
  border-radius: 8px;
  z-index: 9999;
}

.main__header {
  width: 63px;
  height: 20px;
  margin-bottom: var(--spacing);
  font-size: 15px;
  line-height: 20px;
}

.primary__bold__text {
  color: var(--gray-2);
  font-weight: bold;
}

.secondary__bold__text {
  color: var(--text-light-color);
  font-weight: bold;
}

.main__preview {
  width: 260px;
  height: 145px;
  background: var(--preview-background);
  margin-bottom: var(--spacing);
  text-align: center;
  line-height: 17;
  border: 1px solid #E6E6FF;
  box-sizing: border-box;
  border-radius: 8px;
}

.inner__container {
  margin: var(--spacing);
}

.option {
  display: inline-block;
  margin-bottom: var(--spacing);
}

.circular__icon {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  line-height: 37px;
  background: var(--text-light-color);
  opacity: 0.5;
}

.circular__icon:hover {
  background: var(--blue-hover-color);
}

.active .radio__item>label {
  color: var(--text-bold-color);
}

.active .circular__icon {
  background: var(--line-color);
  opacity: 1;
}

.circular__icon>i {
  width: 14px;
  height: 14px;
}

.active .circular__icon>i {
  border: 0px solid var(--primary-color);
}

.circular__icon>i {
  border: 0.5px solid #F2F2F2;
}

.icon {
  display: inline-block;
}

.cam__only {
  background: url(../../images/cam_only.svg);
}

.cam_only__preview {
  content: url(../../images/cam_only_preview.svg);
}

.screen__only {
  background: url(../../images/screen_only.svg);
}

.screen_only__preview {
  content: url(../../images/screen_only_preview.svg);
}

.screen__cam {
  background: url(../../images/screen_cam.svg);
}

.screen_cam__preview {
  content: url(../../images/screen_cam_preview.svg);
}

.option>* {
  float: left;
}

.option>.secondary__bold__text {
  width: 215px;
  height: 20px;
  font-size: 12px;
  line-height: 2.5;
  margin-left: 15px;
}

.audio__options {
  margin-top: var(--spacing);
  margin-bottom: var(--spacing);
}

.large__button {
  width: 100%;
  color: white;
  border-radius: 5px;
  height: 50px;
  border: 0px;
  background: var(--secondary-color);
  margin-top: var(--spacing);
  cursor: pointer;
}

/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 14.58px;
}

/* Hide default HTML checkbox */

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
  background-color: var(--gray-3);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  border-radius: 50%;
  content: "";
  height: 13px;
  width: 13px;
  left: 1px;
  bottom: 0.3px;
  background-color: var(--white);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--primary-color);
}

input:focus+.slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked+.slider:before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px);
}

/* Customize the label (the container) */

.radio__item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  width: 218px;
  margin: 7px 0 0;
}

.radio__item>label {
  width: 100%;
}

.radio__item input[type='radio'] {
  display: none;
}

.radio__item label.for__radio:after {
  content: " ";
  float: right;
  margin: 0 5px 0 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 0.5px solid var(--primary-color);
  background-color: transparent;
}

.radio__item input[type=radio]:checked+label.for__radio:before {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 3.19px;
  left: 208px;
  content: " ";
  display: block;
  background: var(--primary-color);
}

.ex__avatar__video {
  position: fixed;
  width: 200px;
  height: 200px;
  left: 40px;
  bottom: 5%;
  background-blend-mode: normal;
  filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  z-index: 2;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.ex__video__options {
  /* width: 330px; */
  height: 50px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 5%;
  margin: 0 auto;
  text-align: center;
}

.ex__icon__button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--secondary-text);
  border: none;
}

.ex__icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.ex__pause {
  background: url(../../images/pause.svg)
}

.ex__resume {
  background: url(../../images/restart.svg)
}

.ex__stop {
  background: url(../../images/stop.svg)
}

.ex__restart {
  background: url(../../images/restart.svg)
}

.ex__download {
  background: url(../../images/download__ext.svg)
}

.ex__settings {
  background: url(../../images/settings.svg);
}

.ex__live {
  background: url(../../images/live.svg);
}

.ex__cancel {
  background: url(../../images/cancel.svg)
}

.ex__close {
  background: url(../../images/cancel_1.svg)
}

.ex__save {
  background: url(../../images/save.svg)
}

.ex__video__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--alt-overlay-color);
  z-index: 1;
}

.ex__video__backdrop:not(.no__background) {
  width: 100%;
  height: 100%;
}

.no__background {
  background: none!important;
}

.no__display {
  display: none!important;
}

.no__border {
  border: none!important;
}

.no__border:active, .no__border:focus {
  outline: none;
}

.ex__video__timer {
  width: 100px;
  height: 100px;
  margin: 45vh auto auto;
  border-radius: 50%;
  background: var(--preview-background);
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
  font-size: 41.89px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: var(--black);
}

#horus-timer {
  display: none;
}

#resume-recording {
  display: none;
}

#horus-modal {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 40vh;
}

/*open modal*/

.ex__modal {
  width: 494px;
  height: 236px;
  background: var(--white);
  border-radius: 10px;
  text-align: center;
  position: absolute;
  top: 30vh;
  left: 40vw;
}

.ex__modal>div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 160px;
  right: 0;
  width: 375px;
  margin: auto;
}

.ex__modal>div>p {
  width: 375px;
  height: 44px;
  margin: auto;
  padding-top: 15px;
  font-weight: bold;
  font-size: 15px;
  line-height: 3;
  color: var(--text-bold-color);
  text-align: center;
}

.ex__modal__button {
  width: 190px;
  height: 40px;
  border-radius: 4px;
  display: block;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
}

.ex__warning__modal__button {
  background: var(--white);
  border: 1px solid var(--red);
  color: var(--red);
}

.ex__success__modal__button {
  background: var(--secondary-color);
  color: var(--white);
  border: none;
}

.ex__modal>div>a {
  color: var(--primary-color);
}

.ex__link__button {
  border: none;
  background: none;
  color: var(--text-bold-color);
}

.ex__save__loader {
  display: inline-table;
  float: left;
  margin-left: 15px;
  text-align: left;
}

.ex__light__text {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: var(--neutral-black);
}

.ex__loader__progress {
  background: var(--neutral-gray);
  border-radius: 30px;
  height: 5px;
  width: 335px;
  margin-top: 5px;
}

.ex__loading_indicator {
  width: 70px;
  height: 5px;
  background: var(--primary-color);
  border-radius: 40px;
  position: absolute;
  animation: line 1.5s linear infinite;
  -webkit-animation: line 1.5s linear infinite;
}

#modal-button-secondary {
  margin-top: 10px;
  color: var(--text-bold-color);
}

.ex__modal__actions {
  text-align: left;
  margin-top: 25px;
}

.ex__modal__form__header {
  height: 20px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: var(--gray-2);
  float: left;
}

.ex__modal__button__1 {
  height: 40px;
  background: var(--primary-color);
  border: none;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: var(--white);
  width: 105px;
  margin-right: 20px;
}

.ex__modal__button__1:hover {
  background: var(--white);
  color: var(--text-bold-color);
  border: 1px solid var(--primary-color);
}

.ex__modal__button__1:disabled {
  background: var(--disabled-button-color);
  color: var(--text-bold-color);
}

.ex__modal__link {
  color: var(--primary-color);
}

.ex__modal__link:hover {
  color: var(--blue-hover-color);
}

.ex__modal__link:disabled {
  color: var(--blue-disabled-color);
}

.ex__modal__input {
  width: 100%;
  height: 50px;
  background: var(--white);
  border: 1px solid var(--blue-hover-color);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
}

.ex__modal__input:hover, .ex__modal__input:active, .ex__modal__input:focus, .ex__modal__input:target, .ex__modal__input:valid {
  outline: none;
  border: 1px solid var(--primary-color);
  color: var(--text-bold-color);
  font-weight: bold;
}

.ex__modal__input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: var(--blue-hover-color);
  /* Firefox */
  opacity: 1;
}

.ex__modal__input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: var(--blue-hover-color);
}

.ex__modal__input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: var(--blue-hover-color);
}

.ex__modal a {
  text-decoration: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  color: var(--primary-color);
}

.ex__settings__bubble {
  list-style-type: none;
  padding: 0px;
  margin: 40px;
  display: inline-block;
  position: absolute;
  bottom: 32px;
  right: 42%;
  width: 121px;
  height: auto;
  background-color: var(--secondary-text);
}

.ex__settings__bubble>li {
  display: inline-block;
}

.ex__tri__right.ex__border.ex__btm__right__in:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
  right: 37%;
  bottom: -17px;
  border: 9px solid;
	border-color:  var(--secondary-text) transparent transparent var(--secondary-text);
}

.ex__tri__right.ex__btm__right__in:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 51%;
  bottom: -17px;
  border: 9px solid;
  border-color: var(--secondary-text) var(--secondary-text) transparent transparent;
}

.advanced__settings__container {
  position: fixed;
  width: 195px;
  height: 232px;
  right: 40%;
  bottom: 30%;
  background: #333333;
  border-radius: 8px;
  z-index: 9999999999999999999;
}

.advanced__settings__container>main {
  margin: 20px;
}

.advanced__settings__container>main>p {
  padding: 0px;
  padding-bottom: 20px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 15px;
  font-family: Lato;
  font-style: normal;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.advanced_settings_body {
  width: 100%;
  margin-bottom: 30px;
}

.advanced_settings_body>button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #4F4F4F;
  display: inline-block;
  margin: 0px 10px;
}

.advanced_settings_body>button>i {
  display: inline-block;
  width: 50px;
  height: 50px;
}

.cam_select {
  background: url(../../images/cam_select.svg);
}

.mic_select {
  background: url(../../images/mic_select.svg);
}

.advanced__done_button {
  width: 100%;
  height: 50px;
  background: #FA4343;
  border-radius: 5px;
  color: #FFFFFF;
}

.advanced__settings__option__container {
  position: fixed;
  width: 300px;
  bottom: 60%;
  right: 37%;
  background: #333333;
  border-radius: 8px;
  color: #FFFFFF;
  font-family: Lato;
  font-style: normal;
  z-index: 9999999999999999999999;
}

.advanced__settings__option__container>main {
  margin: 20px;
}

.advanced__settings__option__container>main>p {
  padding: 0px;
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.advanced__settings__option__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.advanced__settings__option__list>li {
  display: block;
  margin-bottom: 20px;
}

.radio__item>input[type=radio]:checked+label.list__check::after {
  float: right;
  content: "";
  display: inline-block;
  transform: rotate(45deg);
  height: 12px;
  width: 8px;
  border-bottom: 1px solid #FFFFFF;
  border-right: 1px solid #FFFFFF;
}

.ex__time__check {
  width: 125px;
  height: 50px;
  position: fixed;
  top: 40px;
  left: 50%;
  margin-left: -50px;
  color: var(--white);
  background: var(--secondary-text);
  border-radius: 34px;
  text-align: center;
  line-height: 2.8;
  z-index: 999999999999999999999;
}

.is__live__button {
  border: 1px solid #6FCF97;
  box-sizing: border-box;
}

.ex__save__confirm__wrapper {
  width: 375px;
  height: 226px;
  text-align: center;
  color: var(--white);
  position: absolute;
  top: 30vh;
  left: 40vw;
}

.ex__checkmark {
  background: url(../../images/checkmark.svg);
  width: 117px;
  height: 117px;
  display: block;
  margin: auto;
  margin-bottom: 40px
}

.ex__save__confirm__wrapper>p {
  font-weight: bold;
  font-size: 24.27px;
  line-height: 40px;
}

@-webkit-keyframes line {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
    opacity: 0.9;
  }
  80% {
    width: 80%;
    opacity: 0.5;
  }
  100% {
    width: 92%;
    opacity: 0;
  }
}

@keyframes line {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
    opacity: 0.9;
  }
  80% {
    width: 80%;
    opacity: 0.5;
  }
  100% {
    width: 92%;
    opacity: 0;
  }
}