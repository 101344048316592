.share__video {
  background: var(--white);
  border-radius: 4px;
  width: 100%;
  height: 243px;
}

.share__video>div:first-child {
  border-bottom: 1px solid var(--line-color);
  height: 81px;
  width: 100%;
}

.share__video>div>p, .share__video>div>input {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 0px;
  line-height: 5;
  text-align: left;
}

.share__video>div>input {
  border: none;
  display: block;
  width: 100%;
  outline: none;
}

.share__video>div:nth-child(2) {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.share__video>div:nth-child(2)>p {
  margin-bottom: 30px;
}

.copy__button {
  width: 64.3%;
  height: 50px;
  background: var(--white);
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--primary-color);
}

.copy__button:hover {
  border: 1px solid var(--blue-hover-color);
  color: var(--blue-hover-color);
}

.share__modal__header {
  width: 82px;
  height: 20px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: var(--gray-2);
}

.share__modal__button {
  height: 40px;
  background: var(--secondary-color);
  border: none;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: var(--white);
  width: 105px;
  margin-right: 20px;
}

.share__modal__button:hover {
  background: var(--yellow-hover-color);
  color: var(--text-bold-color);
}

.share__modal__button:disabled {
  background: var(--disabled-button-color);
  color: var(--text-bold-color);
}

.share__modal__actions {
  text-align: left;
  margin-top: 20px;
}

.share__modal__copy__link {
  color: var(--primary-color);
}

.share__modal__copy__link:hover {
  color: var(--blue-hover-color);
}

.share__modal__copy__link:disabled {
  color: var(--blue-disabled-color);
}

.video__container__header__mobile {
  display: none;
}

@media screen and (max-width: 600px) {
  .container__header {
    display: none!important;
  }
  .video__container__header__mobile {
    display: block;
    width: 100%;
    color: var(--white);
  }
  .video__wrapper {
    background: #333333;
    height: 100vh;
  }
  .video__main__container, .video__main__container .container__body {
    margin: 0px!important;
  }
  .video__main__container .float__block__right {
    display: none!important;
  }
  .video__container__header__mobile {
    border-bottom: 1px solid var(--gray-2);
  }
  .video__container__header__mobile .regular__text__bold {
    color: #FFFFFF;
    margin: 0px;
    padding: 9px 20px;
    display: inline-block;
    width: 80%;
  }
  .mobile__video__options {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    background: var(--overlay-color);
    height: 100vh;
  }
  .mobile__video__options ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    line-height: 4;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 370px;
    background: var(--white);
    border-radius: 20px 20px 0px 0px;
    width: 100%;
  }
  .mobile__video__options ul li:first-child {
    margin-top: 20px;
  }
  .mobile__video__options ul li {
    margin-left: 20px;
  }
  .mobile__video__options button, .mobile__video__options a {
    color: var(--text-bold-color);
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
  }
  .mobile__video__options a {
    text-decoration: none;
  }
  .mobile__video__options button>.nav__icon, .mobile__video__options a>.nav__icon {
    margin-right: 20px;
  }
}