.horus__navbar {
  width: 100%;
  height: 80px;
  background: white;
  line-height: 4;
  display: flex;
  justify-content: space-between;
}

.horus__navbar>ul {
  text-align: center;
}

.navbar__header>a {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
  vertical-align: super;
}

.navbar__header>a:hover {
  text-decoration: none;
}

.horus__navbar ul {
  list-style-type: none;
  padding: 0;
  display: inline-block;
}

.horus__navbar ul li {
  display: inline-block;
}

.navbar__header {
  margin-top: 0.5%;
  margin-left: 50px;
  width: 15%;
}

.horus__navbar>ul li a {
  padding: 14px 16px;
  text-decoration: none;
  color: var(--text-light-color);
}

.horus__navbar ul li.active {
  border-bottom: 2px solid var(--primary-color);
}

.horus__navbar ul li.active a {
  color: var(--primary-color);
  font-weight: bold;
}

.horus__navbar ul li a:hover {
  color: var(--blue-hover-color);
  font-weight: bold;
}

.nav__icon {
  height: 20px;
  width: 20px;
  display: inline-block;
}

.nav__button {
  border: 0;
  background: none;
}

.nav__button:focus, .nav__button:active {
  outline: none;
}

.nav__icon.search {
  mask: url(../../images/search.svg);
  background: var(--text-bold-color);
}

.nav__icon.hamburger {
  mask: url(../../images/hamburger.svg);
  background: var(--text-bold-color);
}

.nav__icon.notification {
  mask: url(../../images/notification.svg);
  background: var(--text-bold-color);
}

.nav__icon.link {
  mask: url(../../images/notification_link.svg);
  background: var(--text-bold-color);
}

.nav__button>.nav__icon.avatar.active, .nav__button>.nav__icon.avatar:hover {
  opacity: 0.7;
}

.nav__button>.nav__icon.active {
  background: var(--primary-color);
}

.nav__button>.nav__icon:hover {
  background: var(--blue-hover-color);
}

.navbar__profile {
  width: 20%;
  margin-right: 50px;
  text-align: right;
}

.navbar__profile>ul>li:not(:last-child) {
  margin-right: 30px;
}

.navbar__logo {
  width: 30px;
  height: 30px;
}

.dropdown-menu {
  position: absolute;
  top: 80px;
  right: 50px;
  background: var(--white);
  box-shadow: 0px 4px 30px rgba(218, 200, 233, 0.3);
  border-radius: 4px;
  text-align: left;
  padding-left: 20px!important;
  padding-right: 20px!important;
  z-index: 9999;
}

.dropdown-item {
  display: block!important;
}

.dropdown-item a {
  padding: 0px!important;
  display: block;
  line-height: 0;
  margin-top: 20px;
  font-weight: bold;
  font-size: 15px;
  color: var(--text-bold-color);
  text-decoration: none;
}

.dropdown-item span {
  font-weight: bold;
  font-size: 12px;
  color: var(--gray);
}

.signout {
  color: var(--red)!important;
  text-decoration: underline!important;
  font-weight: bold!important;
  font-size: 14px!important;
}

.signout:hover {
  color: var(--red-hover)!important;
}

.dropdown-menu input {
  border: none;
  width: 80%;
  height: 50px;
  color: var(--text-bold-color);
  font-weight: bold;
  font-size: 12px;
}

.dropdown-menu input:active, .dropdown-menu input:focus {
  outline: none;
}

.dropdown-menu .border-top {
  border-top: 1px solid var(--line-color);
}

.dropdown-menu.notifications {
  padding-left: 30px!important;
  padding-right: 30px!important;
}

.dropdown-menu.notifications>li {
  font-size: 16px;
  line-height: 26px;
  color: var(--text-bold-color);
  padding-top: 25px;
}

.dropdown-menu.notifications>p {
  margin: 30px 0 0;
  padding-bottom: 30px;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: var(--overlay-color);
  border-bottom: 1px solid var(--line-color);
}

.dropdown-menu.notifications>li>div {
  display: table-row;
}

.dropdown-menu.notifications>li>div>div {
  display: table-cell;
}

.dropdown-menu.notifications>li>div>div:first-of-type {
  padding-right: 20px;
  vertical-align: middle;
}

.dropdown-menu.notifications a {
  display: initial;
  color: var(--primary-color);
  text-decoration: underline;
}

.mobile__navbar__options {
  display: none;
}

@media screen and (max-width: 600px) {
  .main__navbar, .navbar__profile {
    display: none!important;
  }
  .navbar__header {
    margin-top: inherit;
    margin-left: 20px;
    width: 85px;
  }
  .horus__navbar {
    height: 44px;
  }
  .mobile__navbar__options {
    display: inherit;
  }
}