.toaster {
  position: fixed;
  bottom: calc(5% + 40px);
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 223px;
  height: 50px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.3));
  background: var(--secondary-text);
  border-radius: 5px;
  color: var(--white);
  font-weight: bold;
  font-size: 12px;
  line-height: 4;
  z-index: 999;
}
.toaster>p {
  width: calc(100% - 20px);
  margin-left: 20px;
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
}
.toaster__icon__button {
  margin-left: 20px;
  margin-right: 20px;
  border: none;
  outline: none;
  background: none;
  padding: 0;
}
.times__icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  mask: url(../../images/times.svg);
  -webkit-mask: url(../../images/times.svg);
  background: var(--text-light-color);
}
.times__icon:hover {
  background: var(--white);
}