.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--overlay-color);
  z-index: 1;
}

.modal {
  width: 494px;
  height: 236px;
  background: var(--white);
  border-radius: 10px;
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.modal__icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.modal__icon.close {
  background: url(../../images/close.svg)
}

.link__button {
  border: none;
  background: none;
}

.modal__body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 160px;
  right: 0;
  width: 375px;
  margin: auto;
}

.modal input {
  width: 100%;
  height: 50px;
  background: var(--white);
  border: 1px solid var(--blue-hover-color);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
}

.modal input:hover, .modal input:active, .modal input:focus, .modal input:target, .modal input:valid {
  outline: none;
  border: 1px solid var(--primary-color);
  color: var(--text-bold-color);
  font-weight: bold;
}

.modal input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: var(--blue-hover-color);
  /* Firefox */
  opacity: 1;
}

.modal input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: var(--blue-hover-color);
}

.modal input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: var(--blue-hover-color);
}

.modal a {
  text-decoration: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  color: var(--primary-color);
}

@media screen and (max-width: 600px) {
  .modal {
    height: 100vh;
    border-radius: 0px;
  }
  .modal__body {
    margin: 96px 2vw auto;
    width: 96vw;
    border-top: 1px solid var(--line-color);
    position: initial;
    padding-top: 30px;
  }
  .modal>button {
    position: absolute;
    top: 56px;
    left: 91vw;
    float: unset!important;
    margin: unset!important;
  }
}